/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, string } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import Media from 'react-media';
import withStyles from '@material-ui/core/styles/withStyles';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import SubCollectionSelector from './Partials/SubCollectionSelector';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import SubCollectionV2 from '../SubCollectionV2/SubCollectionV2';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getPresentationFamily, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    root: {
        position: 'relative',
        margin: '0 auto 32px',
        width: '100%',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
        },
    },
    rootWithGrid: {
        position: 'relative',
        margin: '0 auto',
        width: '100%',
    },
});

const SubCollection = ({
    data: blockData, ssrDeviceType, classes, presentationFamily, subCollection,
}) => {
    const { data, loading } = useUIDQueryConditionally({ data: blockData });
    if (!data || !data.reference || !data.reference.length) return null;
    const {
        heading,
        subcollection_brand: subcollectionBrand,
        presentation_type: presentationType,
        pim_code: collectionIdentifier,
        linking,
        unique_selector,
        mobile_presentation_type: mobilePresentationType,
        hide_availability_message: hideAvailabilityMessage,
        uid,
        standard_sub_collection_style,
    } = data.reference[0];
    const collectionId = data.altPimCode || collectionIdentifier;
    const dataUniqueId = uniqueId(unique_selector);
    const devicePageSize = ssrDeviceType === 'desktop' ? data.reference[0]?.page_size : data.reference[0].page_size_mobile;
    const collectionLink = linking?.link?.href || '';
    const backgroundColor = standard_sub_collection_style?.background_color?.color;
    const backgroundImage = standard_sub_collection_style?.background_image?.url;
    const fontWeight = standard_sub_collection_style?.font_weight || 'bold';
    const textColor = standard_sub_collection_style?.title_color?.color || '#ffffff';
    const isShowBackground = standard_sub_collection_style?.header_background || false;
    const viewAllButton = linking?.link_copy || 'View All';
    const widthStyle = standard_sub_collection_style?.width_style || 1400;
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const rootCssStyle = ffHasHomepageGridStructure ? `${classes.rootWithGrid} subcollection_block` : `${classes.root} subcollection_block`;
    // presentationType is for Desktop
    // mobilePresentationType is for Mobile
    if (data?.reference?.[0]?._content_type_uid === 'simple_subcollection') {
        if (presentationType === 'Standard' || presentationType === 'Carousel' || presentationType === 'Standard V2') {
            return (
                <div className={rootCssStyle} data-uniqueid={dataUniqueId} cmsuid={uid}>
                    <Media query="(max-width: 599px)" defaultMatches={ssrDeviceType === 'mobile'}>
                        {(matches) => {
                            if (loading && matches && blockData?.mobile_skeleton) {
                                return <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.skeleton_height} />;
                            }
                            if (loading && !matches && blockData?.desktop_skeleton) {
                                return <GenericSkeleton height={blockData?.skeleton_height} />;
                            }
                            return (
                                matches ? (
                                    <SubCollectionSelector
                                        dataObj={{
                                            trackData: linking, heading, subcollectionBrand, collectionLink, collectionId, devicePageSize, hideAvailabilityMessage, presentationType: mobilePresentationType || 'Standard', backgroundColor, backgroundImage, isShowBackground, viewAllButton, widthStyle, fontWeight, textColor,
                                        }}
                                        ssrDeviceType={ssrDeviceType}
                                    />
                                ) : (
                                    <SubCollectionSelector
                                        dataObj={{
                                            trackData: linking, heading, subcollectionBrand, collectionLink, presentationType, collectionId, devicePageSize, hideAvailabilityMessage, backgroundColor, backgroundImage, isShowBackground, viewAllButton, widthStyle, fontWeight, textColor,
                                        }}
                                        ssrDeviceType={ssrDeviceType}
                                    />
                                )
                            );
                        }}
                    </Media>

                </div>
            );
        }
    } else {
        return (
            ((subCollection === 'Variant' && presentationFamily === 'flower')  || presentationFamily === 'food' || (data?.reference?.[0]?._content_type_uid === 'subcollection_v2' && presentationFamily === 'flower'))
        && (
            <SubCollectionV2 data={data} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />
        )
        );
    }

    return null;
};

SubCollection.propTypes = {
    data: object.isRequired,
    ssrDeviceType: string.isRequired,
    classes: object.isRequired,
    presentationFamily: string.isRequired,
    subCollection: string,
};
SubCollection.defaultProps = {
    subCollection: '',
};

const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
    subCollection: getActiveABTest('sub_collection_v2')(state),
});
const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true }),
);

export default enhance(SubCollection);
