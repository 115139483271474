/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, object, shape, string, func,
} from 'prop-types';
import {  Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import { useUIDSeed } from 'react-uid';
import Product from '../../../../GraphqlComponents/GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';

const styles = (theme) => ({
    mainSliderContainer: {
        width: '100%',
    },
    sliderContainer: {
        '& a': {
            paddingRight: 16,
        },
        position: 'relative',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
            width: '100%',
            paddingLeft: '0px !important',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: 3,
            verticalAlign: 'top',
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0',
            justifyContent: 'center',
            margin: '0',
            '& li': {
                lineHeight: 1,
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.white || 'rgb(47, 47, 47)',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: 5,
                },
            },
        },
    },
    dotNavigation: {
        width: 22,
        height: 2,
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: 5,
        background: theme.palette?.cms?.borderGrayColor || '#ededed',
    },
});

const  SubCollectionV2MobileSlider = ({ classes, productData, track }) => {
    const seed = useUIDSeed();
    const getCard = (tier) => (
        <Product
            partNumber={tier?.partNumber}
            name={tier?.name}
            prices={tier?.prices}
            image={tier?.image}
            url={tier?.seo?.url}
            skuPriceRange={tier?.skuPriceRange}
            availability={tier?.availability}
            isPassportEligible={tier?.isPassportEligible}
            productBrand={tier?.brandId}
            subcollectionV2Style
            showSimpleProductRedesignAbTest={false}
        />
    );

    return (
        <Grid container key={seed(productData)} className={classes.mainSliderContainer}>
            <Slider
                dots
                speed={150}
                centerMode
                centerPadding="20%"
                infinite={false}
                arrows={false}
                slidesToShow={1}
                slidesToScroll={1}
                dotsClass="slick-dots slick-thumb"
                className={`carousel-container ${classes.sliderContainer}`}
                customPaging={() => <div className={classes.dotNavigation} />}
            >
                {productData?.map((tier) => (
                    <div
                        key={seed(tier)}
                        role="presentation"
                        onClick={() => {
                            track.track({
                                eventCategory: track.category,
                                eventAction: track.action,
                                eventLabel: tier?.name,
                            });
                        }}
                    >
                        { getCard(tier)}
                    </div>
                ))}
            </Slider>
        </Grid>
    );
};

SubCollectionV2MobileSlider.propTypes = {
    classes: object.isRequired,
    productData: arrayOf(shape({
        availability: shape({
            deliveryMessage: string,
        }),
        id: string,
        name: string,
        image: shape({
            name: string,
            path: string,
        }),
    })),
    track: shape({
        track: func,
        category: string,
        action: string,
    }),
};
SubCollectionV2MobileSlider.defaultProps = {
    productData: [],
    track: {
        track: () => { },
        category: '',
        action: '',
    },
};
export default  withStyles(styles)(SubCollectionV2MobileSlider);
