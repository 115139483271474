/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import {
    bool, object, shape, string,
} from 'prop-types';
import { decodeHTML } from 'entities';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../../gql';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import SubCollectionCarousel from './SubCollectionCarousel';
import SubCollectionStandard from './SubCollectionStandard';
import SubCollectionStandardV2 from './SubCollectionStandardV2';
import useExperimentServiceAttributes from '../../../../helpers/experimentService/useExperimentServiceAttributes';

const  SubCollectionSelector = ({ brand, dataObj, ssrDeviceType }) => {
    const {
        heading, collectionLink, presentationType, collectionId, devicePageSize, subcollectionBrand, trackData, hideAvailabilityMessage, backgroundColor, backgroundImage, isShowBackground, viewAllButton, widthStyle, fontWeight, textColor,
    } = dataObj;
    const subBrand = subcollectionBrand || brand['domain-name'];
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    const FINDCATEGORY_BY_ID = gql`
    query SubCollectionById($brand: String!, $environment: String!, $id: String!, $locale: String!, $productOptions: ProductOptions${isGraphqlTargetingEnabled ? ', $targeting: [Targeting]' : ''}) {
        findCategoryById(brand: $brand, id: $id, environment: $environment, locale: $locale, productOptions: $productOptions${isGraphqlTargetingEnabled ? ', targeting: $targeting' : ''}) {
            name
            id
            products {
                id, brandId, name, image {
                    name,
                    path,
                    snipe,
                    snipeImagePath,
                    snipeProductEndDate,
                    snipeCategoryEndDate,
                    snipeProductStartDate,
                    snipeCategoryStartDate
                },
                productSkus {
                    id
                  }
                reviews,
                partNumber,
                availability {
                    deliveryMessage
                },
                prices {
                    currency
                    type
                    value
                }
                skuPriceRange {
                    sale {type, value},
                    retail {type, value}
                }
                seo {
                    url
                    title
                }
            }
        }
    }`;

    return (
        <Query
            query={FINDCATEGORY_BY_ID}
            variables={
                {
                    brand: subBrand,
                    environment: GRAPHQL_ENV,
                    locale: 'en-us',
                    productOptions: {
                        page: 1,
                        pageSize: devicePageSize,
                    },
                    id: collectionId,
                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                }
            }
            context={context}
        >
            {({ loading, error, data }) => {
                if (loading && !error && !data) {
                    return <div style={{ height: '80vh' }} />; // prevent header and footer from snapping together
                }
                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: FINDCATEGORY_BY_ID,
                        component: ' SubCollectionSelector.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }
                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: FINDCATEGORY_BY_ID,
                        component: ' SubCollectionSelector.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                if (!data.findCategoryById || !data.findCategoryById.products) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: FINDCATEGORY_BY_ID,
                        component: ' SubCollectionSelector.js',
                        message: 'No product returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                const graphqlProducts = data?.findCategoryById?.products || null;
                // Adding image.url for subcollection carousel and standard
                const products = [];
                graphqlProducts.forEach((product) => {
                    const productData = { ...product };
                    productData.name = decodeHTML(productData.name);
                    if (productData?.image?.length) {
                        // adding url attribute to image by combining image.path and image.name
                        if (productData.image.path && productData.image.name) {
                            productData.image.url = [productData.image.path, productData.image.name, 'c.jpg'].join('');
                        } else {
                            productData.image.url = '';
                        }
                    }
                    products.push(productData);
                });
                if (products && products.length) {
                    const productObj = {
                        heading, collectionLink, products, brand, hideAvailabilityMessage, backgroundColor, backgroundImage, isShowBackground, viewAllButton, widthStyle, fontWeight, textColor,
                    };
                    if (presentationType === 'Carousel') return <SubCollectionCarousel category={{ name: data?.findCategoryById?.name || heading, id: data?.findCategoryById?.id }} trackData={trackData} data={productObj} ssrDeviceType={ssrDeviceType} />;
                    if (presentationType === 'Standard') return <SubCollectionStandard category={{ name: data?.findCategoryById?.name || heading, id: data?.findCategoryById?.id }} trackData={trackData} data={productObj} ssrDeviceType={ssrDeviceType} />;
                    if (presentationType === 'Standard V2') return <SubCollectionStandardV2 category={{ name: data?.findCategoryById?.name || heading, id: data?.findCategoryById?.id }} trackData={trackData} data={productObj} />;
                }
                return null;
            }}
        </Query>
    );
};

SubCollectionSelector.propTypes = {
    brand: object.isRequired,
    ssrDeviceType: string.isRequired,
    dataObj: shape({
        id: string.isRequired,
        title: string.isRequired,
        collectionLink: string.isRequired,
        presentationType: string.isRequired,
        trackData: shape({
            tracking_event_action: string,
            tracking_event_category: string,
            tracking_event_label: string,
        }),
        hideAvailabilityMessage: bool.isRequired,
        backgroundColor: string.isRequired,
        backgroundImage: string.isRequired,
        isShowBackground: bool.isRequired,
        viewAllButton: string.isRequired,
        widthStyle: string.isRequired,
        fontWeight: string.isRequired,
        textColor: string.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps)(SubCollectionSelector);
