/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { decodeHTML } from 'entities';
import {
    object, shape, string, number, arrayOf, func,
} from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { transformCharacters } from '../../../../helpers/markdown/characterTransformer';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        backgroundColor: theme.palette.background?.paper,
        textAlign: 'center',
        color: '#757575',
        fontSize: '14px',
        fontFamily: 'sans-serif',
    },
    listItem: {
        marginRight: '15px',
        cursor: 'pointer',
        textDecoration: 'none',
    },
    title: {
        color: theme.palette.cta1,
        textDecoration: 'none',
        fontWeight: '700',
        display: 'inline-block',
        minHeight: '40px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '50px',
        width: '130px',
        // marginBottom: '10px',
    },
    tileImage: {
        position: 'relative',
        width: '130px',
        height: '142px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
    snipe: {
        width: '100%',
        color: theme.palette.colorAlert,
        bottom: 0,
        padding: '6px 0',
        position: 'absolute',
        overflow: 'hidden',
        fontSize: '0.85em',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        lineHeight: '18px',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(255,255,255,0.82)',
    },
    productPrice: {
        color: theme.palette.cta1,
        textDecoration: 'none',
        fontWeight: '700',
    },
    hidden: {
        display: 'none',
    },
    discountPrice: {
        textDecoration: 'line-through',
        paddingRight: '10px',
    },
    retailPrice: {
        fontWeight: 700,
    },
    salePrice: {
        color: theme.palette.pdp.salePrice || '#a1001a',
        fontWeight: 700,
    },
});

const CategoryCarouselUI = ({
    classes, product, trackData, track, location,
}) => {
    const {
        id: productId, name: productName, image: { name: imagename, path: imagePath }, skuPriceRange,
    } = product;
    const productImagePath = product?.image ? `${imagePath}${imagename}x.jpg` : '';
    const retailPrice = skuPriceRange.retail?.[0].value;
    const salePrice = skuPriceRange.sale?.[0].value;
    const snipeText = '';
    return (
        <Link
            key={productId}
            to={product.seo.url}
            className={classes.listItem}
            data-ga-category={trackData?.tracking_event_category || 'Collection Page'}
            data-ga-action={trackData?.tracking_event_action || 'Product Click'}
            data-ga-label={decodeHTML(productName)}
            role="region"
            aria-label={`product-${productId}`}
            onClick={() => {
                track({
                    eventName: 'product_click',
                    eventCategory: trackData?.tracking_event_category || 'Collection Page',
                    eventAction: trackData?.tracking_event_action || 'Product Click',
                    eventLabel: decodeHTML(productName),
                    products: [
                        {
                            name: decodeHTML(productName),
                            partNumber: productId,
                            categoryId: location?.state?.categoryId || '',
                            price: salePrice || retailPrice || '',
                            categoryName: location?.state?.categoryName || '',
                        },
                    ],
                });
            }}
        >
            <div
                className={classes.tileImage}
                style={{
                    backgroundImage: `url(${productImagePath}?auto=webp)`,
                }}
            >
                {snipeText ? (
                    <div className={classes.snipe}>{snipeText}</div>
                ) : ''}
            </div>
            <div className={classes.title}>
                {transformCharacters(decodeHTML(productName))}
            </div>
            <div className={classes.productPrice}>
                <span className={`${classes.retailPrice} ${retailPrice !== salePrice ? classes.discountPrice : ''}`}>${retailPrice}</span>
                <span className={`${classes.salePrice} ${retailPrice === salePrice ? classes.hidden : ''}`}>${salePrice}</span>
            </div>
        </Link>
    );
};

CategoryCarouselUI.propTypes = {
    product: shape({
        id: string.isRequired,
        name: string.isRequired,
        image: shape({
            name: string.isRequired,
            path: string.isRequired,
        }).isRequired,
        prices: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ).isRequired,
        skuPriceRange: shape({
            sale: arrayOf(
                shape({
                    value: number.isRequired,
                }),
            ),
            retail: arrayOf(
                shape({
                    value: number.isRequired,
                }),
            ),
        }).isRequired,
        seo: shape({
            url: string.isRequired,
        }).isRequired,
    }).isRequired,
    classes: object.isRequired,
    trackData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
        tracking_event_label: string,
    }),
    track: func,
    location: shape({
        state: shape({
            categoryId: string,
            categoryName: string,
        }),
    }).isRequired,
};
CategoryCarouselUI.defaultProps = {
    trackData: {},
    track: () => {},
};

export default  withRouter(withStyles(styles)(CategoryCarouselUI));
