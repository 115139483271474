/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { number, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import SkeletonStyles from './SkeletonStyles';
import customBreakpoints from '../../../helpers/customBreakpoints';

const useStyles = makeStyles((theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    title: {
        color: 'inherit',
        '& h3, & p': {
            margin: '44px 0 20px 0',
            textAlign: 'center',
            fontSize: '26px',
            fontWeight: '400',
            letterSpacing: '.4px',
            color: 'inherit',
            [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                margin: '30px 0 20px 0',
                fontSize: 16,
                fontWeight: 500,
            },
        },
    },

    // Page Elements
    productGrid: (styles) => ({
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto auto auto',
        gridGap: '5px 20px',
        margin: '0 auto',
        maxWidth: '90%',
        [theme.breakpoints.down(600)]: {
            gridTemplateColumns: 'auto auto auto auto',
        },
        '&--product-container': {
            padding: '18px',
            width: styles?.width || '25%',
        },
        '&--product-block': {
            widht: '100%',
            height: '330px',
        },
        '&--product-title': {
            width: '25%',
        },
        '&--product-price': {
            width: '22%',
        },

    }),
    productContainer: {
        display: 'flex',
        margin: '20px auto 20px auto',
        padding: '0 0 10px 0',
        maxWidth: '1400px',
        justifyContent: 'flex-start',
    },
}));

const SubCollecttionV2Skeleton = ({ countBlock, width }) => {
    const classes = useStyles({ width });
    const seed = useUIDSeed();
    return (
        <div data-testid="subCollectionV2_product_skeleton">
            <div className={classes.productContainer}>
                {Array(countBlock).fill({}).map((item, index) => (
                    <div key={seed(index)} className={`${classes.productGrid}--product-container`}>
                        <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                        <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

SubCollecttionV2Skeleton.propTypes = {
    width: string.isRequired,
    countBlock: number.isRequired,
};

export default SubCollecttionV2Skeleton;
