/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

/**
 * @param {boolean} targetingEnabled - enable or disable targeting in query
 */
const findSubCollectionById = (targetingEnabled) => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql`
    query SubCollectionById($brand: String!, $environment: String!, $id: String!, $locale: String!, $productOptions: ProductOptions${targetingEnabled ? ', $targeting: [Targeting]' : ''}){
        findCategoryById(brand: $brand, id: $id, environment: $environment, locale: $locale, productOptions:$productOptions${targetingEnabled ? ', targeting: $targeting' : ''}) {
            name
            id
            products {
                id, name, brandId, brand
                image {
                    name,
                    path,
                    snipe,
                    snipeImagePath,
                    snipeProductEndDate,
                    snipeCategoryEndDate,
                    snipeProductStartDate,
                    snipeCategoryStartDate,
                },
                reviews,
                partNumber,
                availability {
                    deliveryMessage
                },
                prices {
                    currency
                    type
                    value
                }
                skuPriceRange {
                    sale {type, value},
                    retail {type, value}
                }
                seo {
                    url
                    title
                }
            }
        }
    }

`
);

export default findSubCollectionById;
