/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    arrayOf, number, object, shape, string, func, array, bool,
} from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Media from 'react-media';
import useSSRMediaQuery from '../../../../../helpers/hooks/useSSRMediaQuery';
import SubcollectionHeader from './SubcollectionHeader';
import SubCollectionV2Collection from '../SubCollectionV2Collection';
import PassportEyebrow from '../../../PassportBanner/PassportEyebrow';
import SubCollectionBrandsDropDown from '../SubCollectionBrandsDropDown';

const styles = (theme) => ({
    selectedTag: {
        margin: '10px 0px 15px 0px',
        '& .MuiButtonBase-root': {
            fontSize: 14,
            border: `1px solid ${theme.palette.cms?.black || '#333'}`,
            borderRadius: 0,
            textTransform: 'capitalize',
            padding: '2px 25px',
            marginRight: 10,
        },
        '& .MuiTouchRipple-child': {
            background: 'transparent',
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor: theme.palette?.cms?.activeButtonColor || '#E8F1ED',
        },
        '@media screen and (min-width: 600px) and (max-width: 1024px)': {
            margin: '0px 0px 15px 0px',
        },
    },
    levelOneTag: {
        margin: '10px 0px 15px 0px',
        '& .MuiButtonBase-root': {
            fontSize: 16,
            textTransform: 'capitalize',
            padding: '0 10px',
            color: '#cccccc',
            borderLeft: '1px solid black',
            borderRadius: 0,
            lineHeight: 1,
        },
        '& .MuiButtonBase-root:first-child': {
            paddingLeft: 5,
            borderLeft: 0,
        },
        '& .MuiTouchRipple-child': {
            background: 'transparent',
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    activeLevelOneTag: {
        '&.MuiButtonBase-root span': {
            color: '#222222',
            fontSize: 16,
            [theme.breakpoints.down(601)]: {
                fontSize: 12,
            },
        },
    },
    levelTwoTag: {
        margin: '10px 0px 15px 0px',
        lineHeight: 4,
        '& .MuiButtonBase-root': {
            fontSize: 16,
            border: `1px solid ${theme.palette.cms?.activeButtonColor || '#EBEAE6'}`,
            borderRadius: 40,
            textTransform: 'capitalize',
            padding: '15px 40px',
            marginRight: 15,
            color: '#222222',
            lineHeight: 1,
        },
        '& .MuiTouchRipple-child': {
            background: 'transparent',
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor: theme.palette?.cms?.activeButtonColor || '#E8F1ED',
        },
    },
    activeLevelTwoTag: {
        '&.MuiButtonBase-root': {
            background: '#f1edee',
        },
    },
    mainPassportBanner: {
        margin: '10px 0px 15px 0px',
        alignSelf: 'center',
        '@media screen and (min-width: 600px) and (max-width: 1024px)': {
            paddingLeft: '15px',
        },
    },
    activeButton: {
        backgroundColor: theme.palette?.cms?.activeButtonColor || '#E8F1ED',
    },
    passportRightAlign: {
        justifyContent: 'flex-end',
    },
    passportLightAlign: {
        justifyContent: 'flex-start',
    },
});

const  SubCollectionV2Desktop = ({
    classes, data, track, prefetched, isVariant, prefetchedCollectionIds, listOfBrands,
}) => {
    const prefetch = prefetched !== null;

    const [collectionId, setCollectionId] = useState(prefetch ? prefetchedCollectionIds[0] : data?.collection_sources?.collection?.[0]?.collection_id);

    const [brandSelection, setBrandSelection] = useState(0);

    const collectionData = data?.collection_sources?.collection || [];

    const device = useSSRMediaQuery();

    // on click change brand tab
    const handleBrandChange = (index) => {
        setBrandSelection(index);
        setCollectionId(prefetchedCollectionIds[index]);
    };
    // on click set collection Id
    const handleChange = (categoryId, linkItem) => {
        setCollectionId(categoryId);
        track({
            eventCategory: linkItem?.tracking?.tracking_event_category || '',
            eventAction: linkItem?.tracking?.tracking_event_action || '',
            eventLabel: linkItem?.tracking?.tracking_event_label || '',
        });
    };

    if (prefetch && isVariant) {
        const collectionIds = collectionData[brandSelection].collection_id.split(', ');
        const buttonTextAvailable = collectionData[brandSelection].source.split(', ');
        const justifyText = buttonTextAvailable ? classes.passportRightAlign : classes.passportLightAlign;
        const newCollection = [];
        for (let index = 0; index < collectionIds.length; index += 1) {
            newCollection[index] = {
                collection_id: collectionIds[index],
                source: buttonTextAvailable[index],
                tracking: collectionData[brandSelection].tracking,
            };
        }
        return (
            <Grid contanier>
                <Grid item xs={12}>
                    <SubcollectionHeader
                        eventEyebrow={data?.event_eyebrow}
                        viewAllButton={data?.cta_link}
                        headingCopy={data?.heading_copy}
                        headingFontFaimly={data?.heading_font}
                        headingFontColor={data?.heading_font_color?.color}
                        headingFontSize={data?.heading_font_size}
                        parentBrands={data?.product_parent_pbrands}
                        showEyeBrow={data?.show_event_eyebrow}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        { listOfBrands?.length > 0 && (
                            <Grid item xs={12} sm={12} md={7} className={classes.levelOneTag}>
                                {listOfBrands?.map((linkItem, index) => (
                                    <Button className={index === brandSelection && classes.activeLevelOneTag} onClick={() => { handleBrandChange(index); }}>{linkItem}</Button>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {buttonTextAvailable  && (
                            <Grid item xs={12} sm={12} md={7} className={classes.levelTwoTag}>
                                {newCollection?.map((linkItem) => (
                                    <Button className={collectionId === linkItem?.collection_id && classes.activeLevelTwoTag} onClick={() => { handleChange(linkItem?.collection_id, linkItem); }}>{linkItem.source}</Button>
                                ))}
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={5} className={`${classes.mainPassportBanner} passportBanner`}>
                            {data?.show_passport_eyebrow && <PassportEyebrow overideClass={justifyText} copy={data?.eyebrow_copy} />}
                        </Grid>
                    </Grid>
                </Grid>
                <SubCollectionV2Collection
                    arrowColor={data?.slider_arrow_color?.color}
                    collectionId={collectionId}
                    numberOfProducts={data?.number_of_products}
                    prefetched={prefetched[collectionId] || []}
                    track={{
                        track,
                        category: data?.collection_sources?.products_tracking?.tracking_event_category || '',
                        action: data?.collection_sources?.products_tracking?.tracking_event_action || {},
                    }}
                />
            </Grid>
        );
    }

    const findButtonText = collectionData?.some((item) => item?.source);
    const justifyText = findButtonText ? classes.passportRightAlign : classes.passportLightAlign;
    return (
        <Grid contanier>
            <Grid item xs={12}>

                <SubcollectionHeader
                    eventEyebrow={data?.event_eyebrow}
                    viewAllButton={data?.cta_link}
                    headingCopy={data?.heading_copy}
                    headingFontFaimly={data?.heading_font}
                    headingFontColor={data?.heading_font_color?.color}
                    headingFontSize={data?.heading_font_size}
                    parentBrands={data?.product_parent_pbrands}
                    showEyeBrow={data?.show_event_eyebrow}
                />

            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {findButtonText  && (
                        <Media
                            queries={{
                                medium: '(min-width: 600px) and (max-width: 1024px)',
                                large: '(min-width: 1025px)',
                            }}
                            defaultMatches={{ medium: device === 'desktop', large: device === 'desktop' }}
                        >
                            {(matches) => (
                                <>
                                    {matches.large &&   (
                                        <Grid item xs={12} sm={12} md={6} lg={7} className={classes.selectedTag}>
                                            {collectionData?.map((linkItem) => (
                                                <Button className={collectionId === linkItem?.collection_id && classes.activeButton} onClick={() => { handleChange(linkItem?.collection_id, linkItem); }}>{linkItem?.source}</Button>
                                            ))}
                                        </Grid>
                                    )}
                                    {matches.medium &&   (
                                        <Grid Grid item xs={12} sm={6} md={6} className={classes.selectedTag}>
                                            <SubCollectionBrandsDropDown
                                                collectionData={collectionData}
                                                collectionId={collectionId}
                                                setCollectionId={setCollectionId}
                                                track={track}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Media>
                    )}
                    <Grid item xs={12} sm={6} md={6} lg={5} className={classes.mainPassportBanner}>
                        {data?.show_passport_eyebrow && <PassportEyebrow overideClass={justifyText} copy={data?.eyebrow_copy} />}
                    </Grid>
                </Grid>
            </Grid>
            <SubCollectionV2Collection
                arrowColor={data?.slider_arrow_color?.color}
                collectionId={collectionId}
                numberOfProducts={data?.number_of_products}
                track={{
                    track,
                    category: data?.collection_sources?.products_tracking?.tracking_event_category || '',
                    action: data?.collection_sources?.products_tracking?.tracking_event_action || {},
                }}
            />
        </Grid>
    );
};

SubCollectionV2Desktop.propTypes = {
    classes: object.isRequired,
    data: shape({
        title: string,
        reference: arrayOf(shape({
            celebration_passport_text: shape({
                celebration_text: string,
                passport_text: string,
            }),
            event_eyebrow: shape({
                copy_block: string,
                reminder_text: string,
                background_color: shape({
                    color: string,
                }),
            }),
            heading_copy: string,
            heading_font: string,
            heading_font_size: number,
        })),
    }),
    track: func,
    isVariant: bool.isRequired,
    prefetched: object.isRequired,
    prefetchedCollectionIds: array.isRequired,
    listOfBrands: array.isRequired,
};
SubCollectionV2Desktop.defaultProps = {
    data: {},
    track: () => {},
};
export default  withStyles(styles)(SubCollectionV2Desktop);
