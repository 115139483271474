/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
    arrayOf, func, number, shape, string,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Product from '../../../GraphqlComponents/GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import CTA from '../../Buttons/CTA';
import BlockHeading from '../../BlockHeading';

const useStyles = makeStyles((theme) => ({
    mainContainerSubCollection: {
        maxWidth: '1400px',
        margin: '0 auto',
        width: '100%',
        [theme.breakpoints.down(1401)]: {
            padding: '15px 12px',
        },
    },
    viewMoreButton: {
        width: '100%',
        margin: '28px auto 0px auto',
    },
    titleSolitary: {
        fontSize: '2.875rem',
        fontFamily: 'LatoBold, Arial, Verdana, sans-serif',
        letterSpacing: '0.01em',
        [theme.breakpoints.down(1024)]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down(681)]: {
            textAlign: 'left',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '1.5625rem',
        },
        '& p': {
            margin: '0px 0px 12px 0px',
        },
    },
    mainProductContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(24%, 1fr));',
        [theme.breakpoints.down(1025)]: {
            gridTemplateColumns: 'repeat(3, minmax(24%, 1fr))',
        },
        [theme.breakpoints.down(681)]: {
            gridTemplateColumns: 'repeat(2, minmax(24%, 1fr))',
        },
        gap: '16px',
    },
    buttonViewMore: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&>div': {
            width: 'fit-content',
            fontFamily: 'LatoBold, Arial, Verdana, sans-serif',
            fontSize: '1.25rem',
            alignItems: 'center',
            padding: '12px 22px',
            letterSpacing: '0.01em',
            [theme.breakpoints.down(1024)]: {
                padding: '8px 22px 9px',
                letterSpacing: '0.02em',
                fontSize: '1rem',
            },
            [theme.breakpoints.down(600)]: {
                padding: '3px 8px',
                width: '6.4731rem',
            },
        },
        margin: '0px 0px 12px 0px',
    },
    mainHeadingCollection: {
        display: 'flex',
        alignContent: 'flex-start',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h2': {
            margin: '0',
        },
    },
}));

const  SubCollectionStandardV2 = ({
    category,
    trackData,
    data,
    trackEvent,
}) => {
    if (Object.keys(data).length <= 0) return null;

    const classes = useStyles();
    const headingTitle = data?.heading; // render the heading
    const products = data?.products || []; // render the products
    const viewAllButton = data?.viewAllButton; // render the shop now link
    const collectionLink = data?.collectionLink;

    // render the products containers
    const getCard = (tier) => (
        <Product
            partNumber={tier?.partNumber}
            name={tier?.name}
            prices={tier?.prices}
            image={tier?.image}
            url={tier?.seo.url}
            skuPriceRange={tier?.skuPriceRange}
            availability={tier?.availability}
            categoryName={category?.name || ''}
            categoryId={category?.id || ''}
            track
            isPassportEligible={tier?.isPassportEligible}
            className={classes.product}
            trackEvent={trackEvent}
            trackData={trackData}
            showSimpleProductRedesignAbTest={false}
            productBrand={tier?.brandId}
            productReviews={tier?.reviews}
            isCategoryPage
            productSkus={tier?.productSkus}
        />
    );

    return (
        <div className={classes.mainContainerSubCollection}>
            <div className={classes.mainHeadingCollection}>
                {
                    headingTitle && (
                        <BlockHeading heading={headingTitle} />
                    )
                }
                {
                    collectionLink && (
                        <CTA
                            href={collectionLink}
                            copy={viewAllButton}
                            className={classes.buttonViewMore}
                            tracking_event_category={trackData?.tracking_event_category}
                            tracking_event_action={trackData?.tracking_event_action}
                            tracking_event_label={trackData?.tracking_event_label}
                        />
                    )
                }
            </div>

            <div className={classes.mainProductContainer}>
                {
                    products?.map((tier) => (
                        getCard(tier)
                    ))
                }
            </div>
        </div>
    );
};

SubCollectionStandardV2.propTypes = {
    data: shape({
        heading: string,
        collectionLink: string,
        products: arrayOf(
            shape({
                id: string.isRequired,
                name: string.isRequired,
                image: shape({
                    name: string.isRequired,
                    path: string.isRequired,
                    url: string.isRequired,
                }).isRequired,
                prices: arrayOf(
                    shape({
                        value: number.isRequired,
                    }),
                ).isRequired,
                skuPriceRange: shape({
                    sale: arrayOf(
                        shape({
                            value: number.isRequired,
                        }),
                    ),
                    retail: arrayOf(
                        shape({
                            value: number.isRequired,
                        }),
                    ),
                }).isRequired,
                seo: shape({
                    url: string.isRequired,
                }).isRequired,
            }),
        ).isRequired,
    }).isRequired,
    trackEvent: func,
    trackData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
        tracking_event_label: string,
    }),
    category: shape({
        name: string,
        id: string,
    }),
};

SubCollectionStandardV2.defaultProps = {
    trackData: {},
    category: {},
    trackEvent: () => {},
};

export default SubCollectionStandardV2;
