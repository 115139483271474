/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, shape, string, func,
} from 'prop-types';
import {
    FormControl, InputLabel, NativeSelect,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import { hideLabelWithAssistiveTech } from '../../../../helpers/common/helper';

const useStyles = makeStyles((theme) => ({
    selectedTag: {
        margin: '10px 0px 0px 0px',
        '& .MuiNativeSelect-select.MuiNativeSelect-select': {
            background: theme.palette?.cms?.activeButtonColor || '#E8F1ED',
            border: `1px solid ${theme.palette.cms?.black || '#333'}`,
            textAlign: 'center',
            textIndent: '10%',
            width: '214px',
        },
        '& .MuiTouchRipple-child': {
            background: 'transparent',
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor: theme.palette?.cms?.activeButtonColor || '#E8F1ED',
        },
    },
    ariaInvisible: () =>  hideLabelWithAssistiveTech(),
}));

const  SubCollectionBrandsDropDown = ({
    collectionId, collectionData, setCollectionId, track,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.selectedTag}>
            <FormControl>
                <InputLabel
                    shrink
                    htmlFor="subcollection-label-placeholder"
                    className={classes.ariaInvisible}
                >
                    Collection
                </InputLabel>
                <NativeSelect
                    disableUnderline
                    value={collectionId}
                    onChange={(e) => {
                        const tracking = collectionData?.find?.((item) => item?.collection_id === e.target.value)?.tracking || {};
                        track({
                            eventCategory: tracking?.tracking_event_category || '',
                            eventAction: tracking?.tracking_event_action || '',
                            eventLabel: tracking?.tracking_event_label || '',
                        });
                        setCollectionId(e.target.value);
                    }}
                    classes={{
                        root: classes.selectMarginTop,
                        select: classes.select,
                        icon: classes.arrowIcon,
                    }}
                    input={(
                        <Input
                            name="subcollectionList"
                            id="subcollection-label-placeholder"
                            classes={{
                                root: classes.inputMarginTop,
                            }}
                        />
                    )}
                >
                    {collectionData?.map((linkItem) => (
                        <option
                            value={linkItem?.collection_id}
                            key={linkItem?.collection_id}
                        >
                            {linkItem?.source}
                        </option>
                    ))}
                </NativeSelect>
            </FormControl>
        </div>
    );
};
SubCollectionBrandsDropDown.propTypes = {
    collectionData: arrayOf(
        shape({
            linkItem: shape({
                collection_id: string,
                source: string,
            }),
        }),
    ),
    collectionId: string,
    setCollectionId: func,
    track: func,
};
SubCollectionBrandsDropDown.defaultProps = {
    collectionData: [],
    collectionId: '',
    setCollectionId: () => { },
    track: () => { },
};
export default (SubCollectionBrandsDropDown);
