/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, number, string, func, shape,
} from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { decodeHTML } from 'entities';
import mbpLogger from 'mbp-logger';
import { useMediaQuery } from '@material-ui/core';
import { GRAPHQL_ENV } from '../../../../gql';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import SubCollecttionV2Skeleton from '../../../GraphqlComponents/GraphqlSkeletonComponents/SubCollecttionV2Skeleton';
import SubCollectionV2Slider from './Desktop/SubCollectionV2Slider';
import SubCollectionV2MobileSlider from './Mobile/SubCollectionV2MobileSlider';
import MobileSubCollecttionV2Skeleton from '../../../GraphqlComponents/GraphqlSkeletonComponents/MobileSubCollecttionV2Skeleton';
import DesktopMobile from '../../../../helpers/DesktopMobile/DesktopMobile';
import useExperimentServiceAttributes from '../../../../helpers/experimentService/useExperimentServiceAttributes';

const findCategoryById = (targetingEnabled) => (
    gql`
    query SubCollectionById($brand: String!, $environment: String!, $id: String!, $locale: String!, $productOptions: ProductOptions${targetingEnabled ? ', $targeting: [Targeting]' : '' }){
        findCategoryById(brand: $brand, id: $id, environment: $environment, locale: $locale, productOptions:$productOptions${targetingEnabled ? ', targeting: $targeting' : '' }) {
            name
            id
            products {
                id, name, brandId,
                image {
                    name,
                    path,
                    snipe,
                    snipeImagePath,
                    snipeProductEndDate,
                    snipeCategoryEndDate,
                    snipeProductStartDate,
                    snipeCategoryStartDate,
                },
                reviews,
                partNumber,
                availability {
                    deliveryMessage
                },
                prices {
                    currency
                    type
                    value
                }
                skuPriceRange {
                    sale {type, value},
                    retail {type, value}
                }
                seo {
                    url
                    title
                }
            }
        }
    }`
);

const  SubCollectionV2Collection = ({
    collectionId, brand, numberOfProducts, numberofMobileProduct, arrowColor, track,
}) => {
    const isDesktopDevice = useMediaQuery('(min-width: 601px)');
    const subBrand = brand['domain-name'];
    const productNumber = isDesktopDevice ? numberOfProducts : numberofMobileProduct;
    const renderSkeleton = isDesktopDevice ? <SubCollecttionV2Skeleton countBlock={4} width="25%" /> : <MobileSubCollecttionV2Skeleton />;
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    const FINDCATEGORY_BY_ID = findCategoryById(isGraphqlTargetingEnabled);

    return (
        <Query
            query={FINDCATEGORY_BY_ID}
            variables={
                {
                    brand: subBrand,
                    environment: GRAPHQL_ENV,
                    locale: 'en-us',
                    productOptions: {
                        page: 1,
                        pageSize: productNumber || 8,
                    },
                    id: collectionId,
                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                }
            }
            context={context}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return renderSkeleton;
                }
                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: FINDCATEGORY_BY_ID,
                        component: ' SubCollectionSelector.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }
                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: FINDCATEGORY_BY_ID,
                        component: ' SubCollectionSelector.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                if (!data.findCategoryById || !data.findCategoryById.products) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: FINDCATEGORY_BY_ID,
                        component: ' SubCollectionSelector.js',
                        message: 'No product returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                const subCollectionData = data?.findCategoryById?.products || null;
                const products = [];
                subCollectionData.forEach((product) => {
                    const productData = { ...product };
                    productData.name = decodeHTML(productData.name);
                    if (productData?.image?.length) {
                        // adding url attribute to image by combining image.path and image.name
                        if (productData.image.path && productData.image.name) {
                            productData.image.url = [productData.image.path, productData.image.name, 'c.jpg'].join('');
                        } else {
                            productData.image.url = '';
                        }
                    }
                    products.push(productData);
                });

                if (products && products.length > 0) {
                    return (
                        <DesktopMobile
                            breakpoint={768}
                            desktop={() => <SubCollectionV2Slider arrowColor={arrowColor} productData={products} track={track} />}
                            mobile={() => <SubCollectionV2MobileSlider productData={products} track={track} />}
                        />
                    );
                }
                return null;
            }}
        </Query>
    );
};

SubCollectionV2Collection.propTypes = {
    collectionId: number.isRequired,
    brand: object.isRequired,
    numberOfProducts: number.isRequired,
    numberofMobileProduct: number.isRequired,
    arrowColor: string.isRequired,
    track: shape({
        track: func,
        category: string,
        action: string,
    }),
};

SubCollectionV2Collection.defaultProps = {
    track: {
        track: () => {},
        category: '',
        action: '',
    },
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});
export default connect(mapStateToProps)(SubCollectionV2Collection);
