/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, object, shape, string, func,
} from 'prop-types';
import {  Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { useUIDSeed } from 'react-uid';
import Product from '../../../../GraphqlComponents/GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import NavigationPaginate from '../../../Buttons/NavigationPaginate';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    mainSliderContainer: {
        width: '100%',
        paddingTop: 10,
    },
    sliderContainer: {
        '& a': {
            paddingRight: 16,
        },
        position: 'relative',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
            width: '100%',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: 3,
            verticalAlign: 'top',
        },
        '& .backwardArrow, & .forwardArrow': {
            cursor: 'pointer',
            bottom: '-37px',
            width: 40,
            height: 40,
            position: 'absolute',
        },
        '& .backwardArrow': {
            right: '70px',
            zIndex: 998,
        },
        '& .forwardArrow': {
            right: '20px',
        },
        '& .slick-disabled': {
            '& .back': {
                opacity: '0.1',
            },
            '& .forward': {
                opacity: '0.1',
            },
            borderColor: `${theme.palette.cms?.horizentalLineBorder || '#DEDEDE'} !important`,
            backgroundColor: '#00000008 !important',
            color: theme.palette.cms?.diabledBorderColor || '#DCDCDC',
            cursor: 'default',
        },
    },
    sliderContainerWithGrid: {
        '& .slick-list': {
            paddingBottom: '16px',
        },
    },
});

const  SubCollectionV2Slider = ({
    classes, productData, arrowColor, track,
}) => {
    const seed = useUIDSeed();
    const getCard = (tier) => (
        <Product
            partNumber={tier?.partNumber}
            name={tier?.name}
            prices={tier?.prices}
            image={tier?.image}
            url={tier?.seo?.url}
            skuPriceRange={tier?.skuPriceRange}
            availability={tier?.availability}
            isPassportEligible={tier?.isPassportEligible}
            productBrand={tier?.brandId}
            subcollectionV2Style
            showSimpleProductRedesignAbTest={false}
        />
    );
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const sliderContainerCssStyle = `${classes.sliderContainer} ${ffHasHomepageGridStructure && classes.sliderContainerWithGrid}`;
    return (
        <Grid container key={seed(productData)}  className={classes.mainSliderContainer}>
            <Slider
                dots={false}
                speed={150}
                infinite={false}
                slidesToShow={4}
                slidesToScroll={4}
                nextArrow={<NavigationPaginate color={arrowColor || '#333333'} className={classes} direction="forward" />}
                prevArrow={<NavigationPaginate color={arrowColor || '#333333'} className={classes} direction="back" />}
                className={`carousel-container ${sliderContainerCssStyle}`}

            >
                {productData?.map((tier) => (
                    <div
                        key={seed(tier)}
                        role="presentation"
                        onClick={() => {
                            track.track({
                                eventCategory: track.category,
                                eventAction: track.action,
                                eventLabel: tier?.name,
                            });
                        }}
                    >
                        { getCard(tier)}
                    </div>
                ))}
            </Slider>
        </Grid>
    );
};

SubCollectionV2Slider.propTypes = {
    classes: object.isRequired,
    productData: arrayOf(shape({
        availability: shape({
            deliveryMessage: string,
        }),
        id: string,
        name: string,
        image: shape({
            name: string,
            path: string,
        }),
    })),
    arrowColor: string.isRequired,
    track: shape({
        track: func,
        category: string,
        action: string,
    }),
};
SubCollectionV2Slider.defaultProps = {
    productData: [],
    track: {
        track: () => { },
        category: '',
        action: '',
    },
};
export default  withStyles(styles)(SubCollectionV2Slider);
