/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {
    object,
    string,
} from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import PassportEyeBrowContainer from './PassportEyeBrowConatiner';

const styles = (theme) => ({
    passportEyebrowContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        height: 'auto',
    },
    ppLogo: {
        height: '30px',
    },
    pipe: {
        backgroundColor: '#777',
        width: '1px',
        height: '1.2em',
        margin: '0 15px',
    },
    message: {
        marginBottom: '-6px',
        [theme.breakpoints.down(1160)]: {
            fontSize: 13,
        },
        [theme.breakpoints.down(768)]: {
            fontSize: 16,
        },
    },
    infoIcon: {
        marginLeft: '7px',
        fontSize: '1.5em',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    infoIconContainer: {
        display: 'flex',
    },

});

const PassportEyebrow = ({ copy, classes, overideClass }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => {
        if (typeof document !== 'undefined' && document.getElementById('root')?.style?.position !== 'static') {
            document.getElementById('root').style.position = 'static';
        }
        setIsModalOpen(true);
    };

    const handleClose = () => setIsModalOpen(false);

    return (
        <>
            <div className={`${classes.passportEyebrowContainer} ${overideClass}`}>
                <img
                    className={classes.ppLogo}
                    src="https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt4ae1d2c6b3a1e511/60d21106b085be4819b89536/vert-logo.png"
                    alt="pass-port logo"
                />
                <span className={classes.pipe} />
                <span className={classes.message}>{copy}</span>
                <span className={classes.infoIconContainer} role="presentation" onClick={handleOpen}>
                    <InfoOutlinedIcon
                        className={classes.infoIcon}
                    />
                </span>

            </div>
            {isModalOpen
                && (
                    <PassportEyeBrowContainer isModalOpen={isModalOpen} handleClose={handleClose} />
                )}
        </>
    );
};
PassportEyebrow.propTypes = {
    classes: object.isRequired,
    overideClass: object,
    copy: string,
};
PassportEyebrow.defaultProps = {
    overideClass: {},
    copy: 'All Products eligible with Celebrations Passport',
};

export default withStyles(styles)(PassportEyebrow);
