/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// import React from 'react';
import { useQuery } from '@apollo/client';
import { decodeHTML } from 'entities';
import { GRAPHQL_ENV } from '../../../../gql';
import findSubCollectionById from '../../../../gql/queries/findSubCollectionById';

export default (brand, numberOfProducts, collectionGroup, targeting = [], context = {}, isGraphqlTargetingEnabled = false) => {
    // const COLLECTION_QUERY = gql`{findContent(brand: "${domain}", environment: "${env}", contentType: "incollection_banner_v2"){ content }}`;

    const subBrand = brand['domain-name'];
    const FINDCATEGORY_BY_ID = findSubCollectionById(isGraphqlTargetingEnabled);

    const prefetchedSubCollectionV2Data = {};
    let colletionCount = 0;
    collectionGroup.forEach((collectionId) => {
        const { error, data } = useQuery(FINDCATEGORY_BY_ID,
            {
                variables: {
                    brand: subBrand,
                    environment: GRAPHQL_ENV,
                    locale: 'en-us',
                    productOptions: {
                        page: 1,
                        pageSize: numberOfProducts || 8,
                    },
                    id: collectionId,
                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                },
                context,
            });

        if (error) return null;

        // prefetchedSubCollectionV2Data[collectionId] = { brand: '', products: [] };
        const subCollectionData = data?.findCategoryById?.products || null;
        let collection = {};
        if (subCollectionData) {
            const products = [];
            subCollectionData.forEach((product) => {
                const productData = { ...product };
                productData.name = decodeHTML(productData.name);
                if (productData?.image?.length) {
                    // adding url attribute to image by combining image.path and image.name
                    if (productData.image.path && productData.image.name) {
                        productData.image.url = [productData.image.path, productData.image.name, 'c.jpg'].join('');
                    } else {
                        productData.image.url = '';
                    }
                }
                products.push(productData);
            });
            collection = { brand: subCollectionData?.[0]?.brand, products };
        }
        prefetchedSubCollectionV2Data[collectionId] = collection;
        colletionCount += 1;
        return null;
    });
    return colletionCount === collectionGroup.length ? prefetchedSubCollectionV2Data : null;
};
