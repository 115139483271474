/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, number, object, shape, string,
} from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SubCollectionV2Desktop from './Partials/Desktop/SubCollectionV2Desktop';
import SubcollectionV2Mobile from './Partials/Mobile/SubcollectionV2Mobile';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import prefetchSubCollectionV2Data from './Partials/prefetchSubCollectionV2Data';

import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import useExperimentServiceAttributes from '../../../helpers/experimentService/useExperimentServiceAttributes';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    subCollectionV2Main: {
        maxWidth: 1400,
        width: '100%',
        margin: '0px auto 62px auto',
        [theme.breakpoints.down('md')]: {
            padding: '0px 10px',
            margin: '0px auto 42px auto',
        },
    },
    subCollectionV2MainWithGrid: {
        maxWidth: 1400,
        width: '100%',
        margin: '0px auto 16px',
        [theme.breakpoints.down('md')]: {
            padding: '0px 10px',
        },
    },
    variantA: {
        margin: '0px auto',
        paddingBottom: 98,
        [theme.breakpoints.down('md')]: {
            margin: '0px auto',
            paddingBottom: 98,
        },
        [theme.breakpoints.down(601)]: {
            margin: '0px auto 15px auto',
            padding: '0 20px',
            '& .slick-dots': {
                display: 'none !important',
            },
            '& .carousel-container': {
                width: '110%',
            },
        },
        '& .subCollectionHeading div p': {
            textAlign: 'left',
            fontSize: 30,
            [theme.breakpoints.down(601)]: {
                fontSize: 24,
                lineHeight: 1.3,
            },
        },
        '& .transformedName': {
            fontSize: 24,
            whiteSpace: 'normal',
            [theme.breakpoints.down(601)]: {
                fontSize: 20,
            },
        },
        '& .priceWrapper span': {
            fontSize: 16,
            [theme.breakpoints.up(1025)]: {
                lineHeight: 4,
            },
            [theme.breakpoints.down(601)]: {
                fontSize: 14,
            },
        },
        '& .passportBanner > div': {
            opacity: 0.4,
            paddingRight: 15,
        },
        '& .MuiCard-root': {
            borderRadius: 10,
        },
        '& .backwardArrow, & .forwardArrow': {
            width: 85,
            height: 85,
            bottom: -90,
            borderColor: '#DEDEDE !important',
        },
        '& .backwardArrow': {
            right: 140,
        },
        '& .back, & .forward': {
            height: 35,
        },
        '& .slick-disabled': {
            backgroundColor: '#00000000 !important',
        },
        '& .slick-track': {
            display: 'flex',
        },
        '& .slick-slide': {
            display: 'inline-flex',
            '& div': {
                height: '100%',
                width: '100%',
            },
            '& .productMain': {
                position: 'relative',
                '& a': {
                    height: '100%',
                    paddingBottom: 110,
                    [theme.breakpoints.down(1025)]: {
                        paddingBottom: 70,
                    },
                    '& div': {
                        height: 'auto',
                    },
                    '& .priceWrapper': {
                        position: 'absolute',
                        bottom: 5,
                        minHeight: 110,
                        [theme.breakpoints.down(1025)]: {
                            minHeight: 70,
                        },
                    },
                },
            },
        },
    },
});

const  SubCollectionV2 = ({
    classes, data,
}) => {
    if (!data || !data?.reference || !data.reference.length) return null;
    const dispatch = useDispatch();
    const brand = useSelector(getBrand);
    const track = ({ eventCategory, eventAction, eventLabel }) => {
        dispatch(trackEvent({
            eventCategory,
            eventAction,
            eventLabel,
        }));
    };
    const subcollectionData = data?.reference?.[0] || {};
    const { targeting, context, isGraphqlTargetingEnabled  } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });

    let fetchedCollections = null;
    const listOfBrands = [];
    const collectionIdsToPrefetch = [];
    const isVariantShown = subcollectionData?.presentation_style === 'Variant A';
    if (isVariantShown) {
        const collectionData = subcollectionData?.collection_sources?.collection || [];
        if (collectionData[0]?.collection_id?.split(', ').length > 0) {
            collectionData.forEach((linkItem) => {
                collectionIdsToPrefetch.push(linkItem.collection_id.split(', ')[0]);
            });
            fetchedCollections = prefetchSubCollectionV2Data(brand, subcollectionData?.number_of_products, collectionIdsToPrefetch, targeting, context, isGraphqlTargetingEnabled);
            for (let index = 0; index < collectionIdsToPrefetch.length; index += 1) {
                listOfBrands[index] = fetchedCollections?.[collectionIdsToPrefetch[index]]?.brand;
            }
        }
    }

    const isDesktopDevice = useMediaQuery('(min-width: 601px)');
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const subCollectionV2mainCssStyle = ffHasHomepageGridStructure ? `${classes.subCollectionV2MainWithGrid} ${isVariantShown && classes.variantA}` : `${classes.subCollectionV2Main} ${isVariantShown && classes.variantA}`;
    return (
        <Grid contanier className={subCollectionV2mainCssStyle}>
            {isDesktopDevice
                ? (
                    <SubCollectionV2Desktop
                        data={subcollectionData}
                        prefetched={fetchedCollections}
                        isVariant={isVariantShown}
                        prefetchedCollectionIds={collectionIdsToPrefetch}
                        listOfBrands={listOfBrands}
                        track={track}
                    />
                ) : (
                    <SubcollectionV2Mobile
                        data={subcollectionData}
                        prefetched={fetchedCollections}
                        isVariant={isVariantShown}
                        prefetchedCollectionIds={collectionIdsToPrefetch}
                        listOfBrands={listOfBrands}
                        track={track}
                    />
                )}
        </Grid>
    );
};

SubCollectionV2.propTypes = {
    classes: object.isRequired,
    data: shape({
        title: string,
        reference: arrayOf(shape({
            celebration_passport_text: shape({
                celebration_text: string,
                passport_text: string,
            }),
            heading_copy: string,
            heading_font: string,
            heading_font_size: number,
        })),
    }),
};
SubCollectionV2.defaultProps = {
    data: {},
};

export default withStyles(styles)(SubCollectionV2);
