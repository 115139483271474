/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, bool, shape, string, number,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import CTA from '../../../Buttons/CTA';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    mobileEyeBrowBanner: {
        display: 'flex',
        fontSize: 13,
        '& p,h1,h2,h3,h4,h5,h6': {
            margin: 0,
            padding: 0,
        },
    },
    mobileHeadingStyle: (styles) => ({
        '& p,h1,h2,h3,h4,h5,h6': {
            margin: '5px 0px',
            lineHeight: '1em',
            fontFamily: parseFont(styles?.fontFamily),
            color: styles?.headingFontColor || '#000000',
            fontSize: styles?.fontSize || '1.5em',
        },
    }),
    adjustPaddingEyeBrow: {
        padding: '8px 10px 8px',
        marginRight: 16,
    },
    mobileReminderText: {
        fontWeight: 600,
        textAlign: 'center',
    },
    mobileEyeBrowCopy: {
        display: 'flex',
        alignItems: 'center',
    },
    mobileViewButton: {
        padding: '14px 0px',
        cursor: 'pointer',
        '& button': {
            width: '130px',
            padding: '8px 2px 9px',
        },
    },
    viewButtonLink: {
        color: theme.palette.cms?.black || '#333',
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 600,
    },
    foodMainHeading: (styles) => ({
        padding: '9px 0px',
        lineHeight: '1em',
        fontFamily: parseFont(styles?.fontFamily),
        color: styles?.headingFontColor || '#000000',
        fontSize: styles?.fontSize || '1.5em',
    }),
    flowerMainHeading: (styles) => ({
        padding: '9px 0px',
        fontSize: styles?.fontSize ||  24,
        fontFamily: parseFont(styles?.fontFamily),
        color: styles?.headingFontColor || '#000000',
    }),
    mobileBrandName: {
        fontSize: 12,
        textTransform: 'uppercase',
        color: theme.palette.cms?.brandNameColor || '#b4b4b4',
        margin: '8px 0px',
        '& span:after': {
            content: '"|"',
            margin: '0px 6px',
        },
        '& span:last-child:after': {
            content: '""',
        },
    },
    withViewAllButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    mobileHeadingFloral: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            '& p,h1,h2,h3,h4,h5,h6': {
                fontSize: '24px !important',
            },
        },
    },
    ctaButton: {
        '& > div': {
            [theme.breakpoints.down(600)]: {
                width: 'max-content',
                alignItems: 'center',
            },
            '& svg': {
                [theme.breakpoints.down(600)]: {
                    width: '0.8em',
                    paddingTop: 0,
                },
            },
        },
    },
}));

const  SubcollectionMobileHeader = ({
    eventEyebrow,
    viewAllButton,
    headingCopy,
    headingFontFaimly,
    parentBrands,
    showEyeBrow,
    presentation_family,
    headingFontColor,
    headingFontSize,
}) => {
    let headingStyle = {};
    headingStyle = {
        fontFamily: headingFontFaimly,
        fontColor: headingFontColor,
        fontSize: headingFontSize,
    };

    const classes = useStyles(headingStyle);

    const headingMain = presentation_family === 'food' ? classes.foodMainHeading : classes.flowerMainHeading;

    return (
        <Grid contanier className={classes.subCollectionHeader}>
            <Grid item xs={12} className="subCollectionHeading">
                {/** Mobile EyeBrow Banner */}
                {showEyeBrow && (
                    <div className={classes.mobileEyeBrowBanner}>
                        <span className={classes.adjustPaddingEyeBrow} style={{ backgroundColor: eventEyebrow?.background_color?.color }}>
                            <ReactMarkdown className={classes.mobileReminderText} source={eventEyebrow?.reminder_text} escapeHtml={false} />
                        </span>
                    </div>
                )}
                {/** Mobile Main Heeading Section */}
                {viewAllButton?.link?.title && viewAllButton?.link?.href
                    ? (
                        <div className={classes.withViewAllButton}>
                            <div className={headingMain}>
                                {headingCopy}
                            </div>
                            <div className={classes.mobileViewButton}>
                                <CTA
                                    href={viewAllButton?.link?.href}
                                    copy={viewAllButton?.link?.title}
                                    title={viewAllButton?.link?.title}
                                    tracking_event_category={viewAllButton?.tracking_event_category}
                                    tracking_event_action={viewAllButton?.tracking_event_action}
                                    tracking_event_label={viewAllButton?.tracking_event_label}
                                    className={classes.ctaButton}
                                />
                            </div>
                        </div>
                    )
                    : (
                        <div>
                            <ReactMarkdown
                                className={presentation_family === 'flower' ? `${classes.mobileHeadingStyle} ${classes.mobileHeadingFloral}` :  classes.mobileHeadingStyle}
                                source={headingCopy}
                                escapeHtml={false}
                            />
                        </div>
                    )}

                {parentBrands?.brand.length > 0 && (
                    <div className={classes.mobileBrandName}>
                        {parentBrands?.brand?.map((brandName) => (
                            <span className={classes.brandNameStyle}>{brandName}</span>
                        ))}
                    </div>
                )}

            </Grid>
        </Grid>
    );
};

SubcollectionMobileHeader.propTypes = {
    eventEyebrow: shape({
        copy_block: string,
        reminder_text: string,
        background_color: shape({
            color: string,
        }),
    }),
    viewAllButton: shape({
        href: string,
        title: string,

    }),
    headingCopy: string.isRequired,
    headingFontFaimly: string.isRequired,
    parentBrands: array.isRequired,
    showEyeBrow: bool.isRequired,
    presentation_family: string.isRequired,
    headingFontColor: string,
    headingFontSize: number,
};
SubcollectionMobileHeader.defaultProps = {
    eventEyebrow: {},
    viewAllButton: {},
    headingFontColor: '',
    headingFontSize: '',
};
const mapStateToProps = (state) => ({
    presentation_family: getPresentationFamily(state),
});

const enhance = compose(
    connect(mapStateToProps, null),
);

export default enhance(SubcollectionMobileHeader);
