/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, arrayOf, number, shape, func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import Media from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CategoryCarouselUI from './CategoryCarouselUI';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        marginBottom: '20px',
        padding: '0 5px',
        textAlign: 'center',
        color: '#757575',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        backgroundColor: theme.palette.background?.paper,
    },
    titleWrapper: {
        display: 'flex',
        width: '100%',
        margin: '12px 0',
    },
    title: {
        color: theme.palette.cta1,
        flex: 1,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontWeight: 700,
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    viewAll: {
        flex: 'none',
        textDecoration: 'none',
        color: theme.palette.cta1,
        cursor: 'pointer',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    tileIcon: {
        width: '75px',
        height: 'auto',
        clipPath: 'ellipse(36px 36px at 38px 41px)',
        webkitClipPath: 'ellipse(36px 36px at 38px 41px)',
    },
});

const SubCollectionCarousel = ({
    classes, data, ssrDeviceType, trackData, track,
}) => (
    // TODO: Create Desktop version
    <Media
        query="(max-width: 599px)"
        defaultMatches={(ssrDeviceType === 'mobile')} // Exclude desktop device sizes
    >
        <div className={classes.root}>
            <div className={classes.titleWrapper}>
                <div aria-hidden="true" className={classes.title}>{data.heading}</div>
                <Link
                    to={data.collectionLink}
                    role="region"
                    aria-label="Viewall"
                    className={classes.viewAll}
                    data-ga-category={trackData?.tracking_event_category || ''}
                    data-ga-action={trackData?.tracking_event_action || ''}
                    data-ga-label={trackData?.tracking_event_label || ''}
                    onClick={() => track({
                        eventLabel: trackData?.tracking_event_label,
                        eventCategory: trackData?.tracking_event_category,
                        eventAction: trackData?.tracking_event_action,
                    })}
                >VIEW ALL
                </Link>
            </div>
            <GridList className={classes.gridList} cols={2.5} cellHeight={110}>
                {data.products.map((product) => (
                    <CategoryCarouselUI trackData={trackData} track={track} key={product.id} product={product} />
                ))}
            </GridList>
        </div>
    </Media>
);

SubCollectionCarousel.defaultProps = {
    ssrDeviceType: '',
    trackData: {},
    track: () => {},
};

SubCollectionCarousel.propTypes = {
    data: shape({
        heading: string,
        collectionLink: string,
        products: arrayOf(
            shape({
                id: string.isRequired,
                name: string.isRequired,
                image: shape({
                    name: string.isRequired,
                    path: string.isRequired,
                    url: string.isRequired,
                }).isRequired,
                prices: arrayOf(
                    shape({
                        value: number.isRequired,
                    }),
                ).isRequired,
                skuPriceRange: shape({
                    sale: arrayOf(
                        shape({
                            value: number.isRequired,
                        }),
                    ),
                    retail: arrayOf(
                        shape({
                            value: number.isRequired,
                        }),
                    ),
                }).isRequired,
                seo: shape({
                    url: string.isRequired,
                }).isRequired,
            }),
        ),
    }).isRequired,
    classes: object.isRequired,
    ssrDeviceType: string,
    trackData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
        tracking_event_label: string,
    }),
    track: func,
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(SubCollectionCarousel));
